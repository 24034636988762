<template>
  <div class="w-100">
    <v-form ref="form" :action="`${socketAddress}/uploadFile`" method="POST" enctype="multipart/form-data">
      <v-file-input v-model="file" multiple id="attachment" name="attachment" label="File input" />
      <v-btn type="submit" color="success">submit</v-btn>
    </v-form>

    <v-form ref="form" @submit.prevent="submit">
      <v-text-field v-model="fileId" name="id" id="id" label="File ID to delete" />
      <v-btn type="submit" color="error">delete</v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'

export default {
  name: 'pibot-attachment-uploader',
  data: () => {
    return {
      file: null,
      fileId: null
    }
  },
  computed: {
    socketAddress () {
      return process.env.VUE_APP_SOCKET_ADDRESS
    }
  },
  methods: {
    ...mapActions({
      uploadAttachment: 'fileUploader/socket_uploadAttachment'
    }),
    async submit (arg) {
      await axios.delete(`${this.socketAddress}/files/${this.fileId}`)
    }
  }
}
</script>
